<template>
  <div>
    <form @submit.prevent @keyup.enter="validateEmail">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="E-mail"
          :type="campoInvalido('userEmail', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('userEmail', 'data') ? 'Insira um e-mail' : ''"
        >
          <b-input
            placeholder="Insira um e-mail"
            v-model="data.userEmail"
            @input="data.userEmail = data.userEmail.toLowerCase()"
          >
          </b-input>
        </b-field>

        <div class="content-administrador">
          <b-field label="Administrador">
            <b-checkbox
              class="checkbox-administrador"
              size="is-large"
              v-model="data.administrator"
            ></b-checkbox>
          </b-field>
        </div>

        <b-field class="mb-1">
          <b-button
            class="button is-primary is-fullwidth invite-button save-button"
            :loading="loading"
            @click.prevent.stop="validateEmail()"
          >
            Convidar
          </b-button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table paginated v-if="users.length" class="table is-fullwidth" :data="users">
      <b-table-column numeric label="ID" width="40" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column sortable label="Usuário" field="name" v-slot="props">
        <div v-if="props.row.pending">
          <strong>{{ props.row.invitationEmail }}</strong>
          <b-tag type="is-warning ml-1">Pendente</b-tag>
        </div>

        <div v-else>
          <strong>{{ props.row.name }}</strong>
          <small class="ml-1"
            ><i>{{ props.row.userEmail }}</i></small
          >
        </div>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="createdAt"
        label="Inserido em"
        width="140"
        v-slot="props"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}
      </b-table-column>

      <b-table-column centered sortable field="function" label="Função" v-slot="props">
        {{ props.row.function === 'ADMINISTRADOR' ? 'Administrador' : 'Membro' }}
      </b-table-column>

      <b-table-column centered label="Permissões" v-slot="props">
        <button
          class="button is-small is-primary is-outlined"
          @click.prevent.stop="viewPermissions(props.row)"
        >
          Selecionar
        </button>
      </b-table-column>

      <b-table-column centered v-slot="props">
        <b-tooltip label="Copiar Convite">
          <button
            class="button mr-1"
            v-if="props.row.pending"
            @click.prevent.stop="copyInvitation(props.index)"
          >
            <b-icon icon="content-copy"></b-icon>
          </button>
        </b-tooltip>

        <b-tooltip label="Excluir">
          <button
            class="button is-danger"
            :disabled="disable.delete"
            @click.prevent.stop="
              deleteUser(props.row.companyId, props.row.companyUserId, props.row.pending)
            "
          >
            <b-icon icon="delete"></b-icon>
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>

    <b-modal
      has-modal-card
      v-if="permissionsModal"
      :active.sync="permissionsModal"
      @permissions="savePermissions"
    >
      <AppPermissions
        buyer
        display-administrator
        :admin-user="adminUser"
        :disable-checks="disableChecks"
        :loading="loading"
        :permissions="permissions"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import field from '@/mixins/formulario';

import AppPermissions from '@/components/AppPermissions';

export default {
  name: 'PrefectureUsers',
  mixins: [field],
  components: {
    AppPermissions,
  },
  data() {
    const data = {
      userEmail: '',
      administrator: false,
    };

    const disable = {
      delete: false,
    };

    const permissions = {
      editCompany: false,
      editUsers: false,
      operateAuctions: false,
      operateConcurrencies: false,
      operateBiddings: false,
    };

    return {
      data,
      disable,
      permissions,
      permissionsModal: false,
      prefectureId: 0,
      prefectureUserId: 0,
      userId: 0,
      adminUser: false,
      disableChecks: false,
      loading: false,
    };
  },
  validations: {
    data: {
      userEmail: { required },
    },
  },
  computed: {
    ...mapState('prefectures', ['prefecture']),
    ...mapGetters({
      users: 'getCompanyUsers',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions([
      'getUsers',
      'invitePrefectureUser',
      'deletePrefectureUser',
      'savePrefecturePermissions',
    ]),
    viewPermissions(row) {
      this.adminUser = row.function === 'ADMINISTRADOR';
      this.prefectureId = row.companyId;
      this.prefectureUserId = row.companyUserId;
      this.userId = row.id;
      this.permissions.editCompany = row.permissions.editCompany;
      this.permissions.editUsers = row.permissions.editUsers;
      this.permissions.operateAuctions = row.permissions.operateAuctions;
      this.permissions.operateConcurrencies = row.permissions.operateConcurrencies;
      this.permissions.operateBiddings = row.permissions.operateBiddings;
      this.permissionsModal = true;
    },
    async savePermissions(permissions) {
      this.loading = true;
      try {
        await this.savePrefecturePermissions({
          empresaId: this.prefectureId,
          id: this.prefectureUserId,
          usuarioId: this.userId,
          ...permissions,
        });
        this.$alerta('Permissões salvas', 'is-success');
        this.$emitBus('close-modal');
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.message === 'ValidationError' &&
          error.response.data.details.message === 'User already buyer administrator'
        ) {
          this.$alerta('Usuário é administrador de outra prefeitura', 'is-danger');
        } else {
          this.$alerta('Erro ao salvar permissões', 'is-danger');
        }
      } finally {
        this.loading = false;
      }
    },
    async validateEmail() {
      if (this.validar('data')) {
        const { userEmail } = this.data;

        const emailExists = this.users.some(
          user =>
            (user.invitationEmail &&
              user.invitationEmail.toLowerCase() === userEmail.toLowerCase()) ||
            (user.userEmail && user.userEmail.toLowerCase() === userEmail.toLowerCase()),
        );

        if (emailExists) {
          this.$alerta('E-mail já existente', 'is-danger', 3000);
        } else if (userEmail.includes('@') && userEmail.includes('.')) {
          this.inviteNewUser();
        } else {
          this.$alerta('Insira um e-mail válido', 'is-danger', 3000);
        }
      }
    },
    async inviteNewUser() {
      const userData = {
        invitationEmail: this.data.userEmail.trim(),
        companyId: this.prefecture.id,
        function: this.data.administrator ? 'ADMINISTRADOR' : 'MEMBRO',
      };

      this.loading = true;
      try {
        await this.invitePrefectureUser(userData);
        this.$alerta('Usuário convidado', 'is-success');
        this.data.userEmail = '';
        this.$v.data.$reset();
      } catch (error) {
        if (error.response.status === 400 && error.response.data.message === 'ValidationError') {
          const errorMessage = error.response.data.details.message;

          if (errorMessage === 'User is already a member with another role') {
            this.$alerta('Usuário participa de um fornecedor', 'is-danger');
            return;
          }
          if (errorMessage === 'User already buyer administrator') {
            this.$alerta('Usuário é administrador de outra prefeitura', 'is-danger');
            return;
          }
        }

        this.$alerta('Erro ao convidar novo usuário', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
    async copyInvitation(id) {
      try {
        const url = `${window.origin}/cadastrar?convite=${this.users[id].invitation}`;
        await this.$copyText(url);
        this.$alerta('Convite copiado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao copiar o convite', 'is-danger');
      }
    },
    async deleteUser(prefectureId, prefectureUserId, pending) {
      const proceed = await this.$confirmacao({
        message: `Tem certeza que deseja excluir o usuário?${
          pending ? ' O convite não poderá ser aceito.' : ''
        }`,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }

      try {
        this.disable.delete = true;
        await this.deletePrefectureUser({
          companyId: prefectureId,
          companyUserId: prefectureUserId,
        });
        this.$alerta('Usuário excluído', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir o usuário', 'is-danger');
      }
      this.disable.delete = false;
    },
  },
  created() {
    this.getUsers(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.invite-button {
  height: 100%;
}

.checkbox-administrador {
  margin-left: 2.4rem;
}

.content-administrador {
  margin-right: 2rem;
}

@media (max-width: 1023px) {
  .checkbox-administrador {
    margin-left: 1rem;
  }

  .content-administrador {
    margin: 0.5rem;

    .field {
      display: flex;
    }
  }
}
</style>
