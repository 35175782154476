<template>
  <div>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <div class="column">
            <h3 class="title has-text-white">Permissões</h3>
            <div class="box">
              <b-table class="permissions-table" :data="permissionsValues">
                <b-table-column label="Descrição" width="250" v-slot="props">
                  <div class="has-text-left">
                    {{ props.row.permission }}
                  </div>
                </b-table-column>

                <b-table-column label="Permitir" v-slot="props">
                  <b-checkbox
                    class="mr-0"
                    id="permission-checkbox"
                    v-model="props.row.option"
                    :disabled="
                      disableChecks ||
                      (displayAdministrator &&
                        adminUser &&
                        props.row.permission !== 'Administrador')
                    "
                  ></b-checkbox>
                </b-table-column>
              </b-table>

              <hr />

              <div class="footer-buttons">
                <button class="button closed-button" @click.stop.prevent="closeModal()">
                  Fechar
                </button>

                <button
                  class="button is-success"
                  :class="{ ' is-loading': loading }"
                  :disabled="disableChecks"
                  @click.stop.prevent="savePermissions()"
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppPermissions',
  props: {
    adminUser: { type: Boolean, default: false },
    buyer: { type: Boolean, default: false },
    disableChecks: { types: Boolean, default: false },
    displayAdministrator: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    permissions: { type: Object, default: () => {} },
  },
  data() {
    return {
      permissionsList: [
        { permission: 'Administrador', option: this.adminUser },
        {
          permission: this.buyer ? 'Editar Perfil da Prefeitura' : 'Editar Perfil da Empresa',
          option: this.permissions.editCompany,
        },
        { permission: 'Cadastro de Usuários', option: this.permissions.editUsers },
        { permission: 'Pregões Eletrônicos', option: this.permissions.operateAuctions },
        { permission: 'Concorrências Eletrônicas', option: this.permissions.operateConcurrencies },
        { permission: 'Dispensas de Licitação', option: this.permissions.operateBiddings },
      ],
    };
  },
  computed: {
    permissionsValues() {
      const list = [...this.permissionsList];
      return this.displayAdministrator ? list : list.splice(1);
    },
  },
  methods: {
    savePermissions() {
      const permissions = this.displayAdministrator
        ? {
            administrador: this.permissionsValues[0].option,
            editarEmpresa: this.permissionsValues[1].option,
            editarUsuarios: this.permissionsValues[2].option,
            operarPregoes: this.permissionsValues[3].option,
            operarConcorrencias: this.permissionsValues[4].option,
            operarDispensas: this.permissionsValues[5].option,
          }
        : {
            editarEmpresa: this.permissionsValues[0].option,
            editarUsuarios: this.permissionsValues[1].option,
            operarPregoes: this.permissionsValues[2].option,
            operarConcorrencias: this.permissionsValues[3].option,
            operarDispensas: this.permissionsValues[4].option,
          };

      this.$parent.$emit('permissions', permissions);
    },
    closeModal() {
      this.$parent.onCancel();
      this.$parent.close();

      this.permissionsList = Object.keys(this.permissionsList).map(permission => {
        const clonePermission = { ...permission };
        clonePermission.option = false;

        return clonePermission;
      });
    },
  },
  created() {
    this.$onBus('close-modal', this.closeModal);
  },
};
</script>

<style lang="scss" scoped>
.closed-button {
  margin-right: 0.4rem;
}

.footer-buttons {
  justify-content: center;
  width: 100%;
}
</style>

<style lang="scss">
#permission-checkbox .control-label {
  padding-left: unset;
}
</style>
